<template>
<div id="page-compras" class="non-printable">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height class="non-printable">
        <v-row align-center justify-center>
            <v-col>
                <base-material-card color="primary" icon="mdi-cash" title="Revisar Precios de Compra" class="elevation-1 px-5 py-3">
                    <v-card-text>
                        <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

                            <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>
                                <template slot="fecha" slot-scope="props">
                                    {{ parse_date_time(props.row.fecha) }}
                                </template>
                                <template slot="fecha_recibio_mercancia" slot-scope="props">
                                    {{ parse_date_time(props.row.fecha_recibio_mercancia) }}
                                </template>

                                <template slot="articulos" slot-scope="props">
                                    <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                                        Ver {{ props.row.articulos.length }} artículos
                                    </v-chip>
                                </template>

                            </v-client-table>
                            <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
                        </div>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

    <!-- MODAL ARTICULOS -->
    <v-dialog v-model="modalArticulos" max-width="99%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Compra</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalArticulos = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>

                    <v-row>
                        <v-col lg="6" md="6" sm="6" v-if="model_vista.proveedor!=null && model_vista.proveedor!=''">
                            <b>Proveedor:</b> {{model_vista.proveedor.nombre_proveedor}}
                        </v-col>
                        <v-col lg="6" md="6" sm="6">
                            <b>Estatus:</b> {{model_vista.estatus}}
                        </v-col>
                    </v-row><br>

                    <v-row>
                        <v-col>
                            <span><strong>NOTA: Además de los artículos en la orden de compra, se incluyen también artículos equivalentes</strong></span>
                            <v-client-table ref="vuetablearticulosorden" :data="model_vista.articulos" :columns="columnsArticulosVista" :options="optionsArticulosVista" class="elevation-2 pa-2" :key="lakey">
                                <template slot="codigo_articulo" slot-scope="props">
                                    {{ props.row.codigo_articulo.join(", ") }}
                                </template>
                                <template slot="fecha_caducidad" slot-scope="props">
                                    <v-chip v-if="props.row.tipo_articulo=='Compra' && props.row.fecha_caducidad!=undefined" x-small color="gray" text-color="black" title="Cambiar Fecha" 
                                        v-tippy @click="showModalFechaCaducidad(props.row)">
                                        <b>{{props.row.fecha_caducidad!=""?props.row.fecha_caducidad.toString().substring(0,10):"N/A"}}</b>
                                    </v-chip>
                                    <span v-else>&nbsp;</span>
                                </template>
                                <template slot="existencia" slot-scope="props">
                                    <div style="text-align:center !important;">
                                        <v-chip x-small color="gray" text-color="black" title="Ver Existencias" v-tippy 
                                            @click="ver_existencias(props.row.id_articulo)">
                                            <b>{{formatNumberDecCero(props.row.existencia,2)}}</b>
                                        </v-chip>
                                    </div>                    
                                </template>
                                <template slot="precio_proveedor" slot-scope="props">
                                    <div style="text-align:center !important;">                        
                                        <v-chip v-if="props.row.tipo_articulo=='Compra'" x-small color="gray" text-color="black" title="Ver Historial" v-tippy 
                                            @click="getCompras(props.row.id_articulo)">
                                            <b>${{props.row.precio_proveedor}}</b>
                                        </v-chip>
                                        <span v-else >
                                            <b>${{props.row.precio_proveedor}}</b>
                                        </span>
                                    </div>                    
                                </template>
                                <template slot="publico" slot-scope="props">
                                    <div v-if="props.row.publico.toString()=='N/A'" style=" text-align:center !important;">
                                            N/A
                                    </div>
                                    <div v-else-if="parseFloat(props.row.publico)>parseFloat(props.row.precio_proveedor) || parseFloat(props.row.precio_proveedor) == 0 " style=" text-align:center !important;">
                                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row, 1,  props.index)">
                                            <b>${{props.row.publico}}</b>
                                        </v-chip>
                                    </div>
                                    <div v-else-if="parseFloat(props.row.publico)<=parseFloat(props.row.precio_proveedor) && parseFloat(props.row.precio_proveedor) > 0 " style=" text-align:center !important;">
                                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row, 1, props.index)">
                                            <b>${{props.row.publico}}</b>
                                        </v-chip>
                                    </div>                                    
                                </template>
                                <template slot="diferencia_precio_publico" slot-scope="props">
                                    <div v-if="props.row.publico=='N/A'" style="text-align:right">&nbsp;</div>
                                    <div v-else style="text-align:right" @click="getRegistro(props.row.id_articulo)">${{(parseFloat(props.row.publico) - parseFloat(props.row.precio_proveedor)).toFixed(2)}}</div>
                                </template>
                                <template slot="utilidad_publico" slot-scope="props">
                                    <div v-if="props.row.precio_proveedor==0" style="text-align:right" @click="getRegistro(props.row.id_articulo)">{{(100).toFixed(2)}}%</div>
                                    <div v-else-if="props.row.publico=='N/A'" style="text-align:right">&nbsp;</div>
                                    <div v-else style="text-align:right" @click="getRegistro(props.row.id_articulo)">{{(((parseFloat(props.row.publico)/parseFloat(props.row.precio_proveedor))-1)*100).toFixed(2)}}%</div>
                                </template>

                                <template slot="mayoreo" slot-scope="props">
                                    <div v-if="props.row.mayoreo.toString()=='N/A'" style=" text-align:center !important;">
                                            N/A
                                    </div>
                                    <div v-else-if="parseFloat(props.row.mayoreo)>parseFloat(props.row.precio_proveedor) || parseFloat(props.row.precio_proveedor) == 0 " style=" text-align:center !important;">
                                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row,2, props.index)">
                                            <b>${{props.row.mayoreo}}</b>
                                        </v-chip>
                                    </div>
                                    <div v-else-if="parseFloat(props.row.mayoreo)<=parseFloat(props.row.precio_proveedor) && parseFloat(props.row.precio_proveedor) > 0 " style=" text-align:center !important;">
                                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row,2, props.index)">
                                            <b>${{props.row.mayoreo}}</b>
                                        </v-chip>
                                    </div>                                    
                                </template>
                                <template slot="diferencia_precio_mayoreo" slot-scope="props">
                                    <div v-if="props.row.mayoreo=='N/A'" style="text-align:right">&nbsp;</div>
                                    <div v-else style="text-align:right" @click="getRegistro(props.row.id_articulo)">${{(parseFloat(props.row.mayoreo) - parseFloat(props.row.precio_proveedor)).toFixed(2)}}</div>
                                </template>
                                <template slot="utilidad_mayoreo" slot-scope="props">
                                    <div v-if="props.row.precio_proveedor==0" style="text-align:right" @click="getRegistro(props.row.id_articulo)">{{(100).toFixed(2)}}%</div>
                                    <div v-else-if="props.row.mayoreo=='N/A'" style="text-align:right">&nbsp;</div>
                                    <div v-else style="text-align:right" @click="getRegistro(props.row.id_articulo)">{{(((parseFloat(props.row.mayoreo)/parseFloat(props.row.precio_proveedor))-1)*100).toFixed(2)}}%</div>
                                </template>

                                <template slot="mayoreo2" slot-scope="props">
                                    <div v-if="props.row.mayoreo2.toString()=='N/A'" style=" text-align:center !important;">
                                            N/A
                                    </div>
                                    <div v-else-if="parseFloat(props.row.mayoreo2)>parseFloat(props.row.precio_proveedor) || parseFloat(props.row.precio_proveedor) == 0 " style=" text-align:center !important;">
                                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row,3, props.index)">
                                            <b>${{props.row.mayoreo2}}</b>
                                        </v-chip>
                                    </div>
                                    <div v-else-if="parseFloat(props.row.mayoreo2)<=parseFloat(props.row.precio_proveedor) && parseFloat(props.row.precio_proveedor) > 0 " style=" text-align:center !important;">
                                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row,3, props.index)">
                                            <b>${{props.row.mayoreo2}}</b>
                                        </v-chip>
                                    </div>                                    
                                </template>   
                                <template slot="diferencia_precio_mayoreo2" slot-scope="props">
                                    <div v-if="props.row.mayoreo2=='N/A'" style="text-align:right">&nbsp;</div>
                                    <div v-else style="text-align:right" @click="getRegistro(props.row.id_articulo)">${{(parseFloat(props.row.mayoreo2) - parseFloat(props.row.precio_proveedor)).toFixed(2)}}</div>
                                </template>
                                <template slot="utilidad_mayoreo2" slot-scope="props">
                                    <div v-if="props.row.precio_proveedor==0" style="text-align:right" @click="getRegistro(props.row.id_articulo)">{{(100).toFixed(2)}}%</div>
                                    <div v-else-if="props.row.mayoreo2=='N/A'" style="text-align:right">&nbsp;</div>
                                    <div v-else style="text-align:right" @click="getRegistro(props.row.id_articulo)">{{(((parseFloat(props.row.mayoreo2)/parseFloat(props.row.precio_proveedor))-1)*100).toFixed(2)}}%</div>
                                </template>

                                <template slot="actions" slot-scope="props">
                                    <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                                        <template v-slot:activator>
                                        <v-btn x-small fab dark color="primary">
                                            <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                                            <v-icon v-else>reorder</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row.id_articulo)" title="Editar Registro" v-tippy>
                                        <v-icon>edit</v-icon>
                                        </v-btn>                    
                                    </v-speed-dial>
                                </template>

                            </v-client-table>
                        </v-col>
                    </v-row>                    
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalArticulos=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="modal" max-width="75%">
        <v-card>
        <v-card-title>
            <v-card class="primary white--text titulomodal">
            <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
            <span class="subheading">
                <strong>{{ update ? "Actualizar" : "Nuevo" }} Artículo</strong>
            </span>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="modal = false" class="close_modal">
            <v-icon class="white--text">cancel</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>        
            
                <fieldset>
                <legend><strong>Información General</strong></legend>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md @keyup.enter="updateRegistro()">
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                        <v-autocomplete disabled readonly v-model="model.codigo_barras" :items="model.codigo_barras" 
                            :hide-no-data="true" :hide-selected="true" multiple chips small-chips deletable-chips label="Código de Barras"                         
                            >
                        </v-autocomplete>                      
                        </v-col>
                        <v-col sm="6" md="6" lg="6">
                        <v-text-field disabled readonly label="Nombre" v-model="model.nombre"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                        <v-text-field label="Descripción" disabled readonly v-model="model.descripcion"></v-text-field>
                        </v-col>
                        <v-col sm="6" md="6" lg="6">
                            <v-text-field label="Categoría" disabled readonly v-model="model.categoria"></v-text-field>                      
                        </v-col>
                    </v-row>                  
                    <v-row>
                        <v-col sm="4" md="4" lg="4">
                        <v-text-field disabled readonly prepend-icon="attach_money" label="Precio Compra S/IVA" v-model="model.precio_compra"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="4" md="4" lg="4">
                        <v-text-field disabled readonly prepend-icon="attach_money" label="Precio Compra C/IVA" v-model="model.precio_compra_con_iva"></v-text-field>
                        </v-col>
                        <v-col sm="4" md="4" lg="4">
                            <v-text-field label="Marca/Modelo" disabled readonly v-model="model.marca"></v-text-field>                      
                        </v-col>
                    </v-row>

                    </v-container>
                </v-form>
                </fieldset>
                <fieldset>
                <legend><strong>Precios de venta:</strong></legend>
                <v-form ref="formprecios" lazy-validation>
                    <v-container grid-list-md>
                    <v-spacer></v-spacer>
                    <v-btn fab dark x-small color="green" v-on:click="addNewPrecio" title="Agregar Precio" v-tippy>
                        <v-icon dark>add</v-icon>
                    </v-btn>
                    <div v-for="(detalle, index) in model.precios_venta" v-bind:key="index">
                        <v-row style="flex-wrap: nowrap;">
                        <v-col cols="3">
                            <v-autocomplete :name="'nombre['+index+']'" v-model="detalle.tipo_precio" :items="tipos_precios" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="nombre" label="Tipo de Precio"
                            :rules="[rules.required, validaTipoPrecio]"></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field :name="'nombre['+index+']'" label="% Aumento" type="text" 
                            @blur="validaPrecioVenta(index)" class="center-input" autocomplete="off" :rules="[validaCantidadFloatNegativo]" @input="setPrecio(index)" v-model="detalle.porcentaje"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field :name="'nombre['+index+']'" label="Precio S/Impuestos" type="text" 
                            @input="setPrecioConImpuesto(index)" 
                            @blur="validaPrecioVenta(index)" 
                            class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat, validaMaximo]"
                            v-model="detalle.precio"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field :name="'nombre['+index+']'" label="Precio C/Impuestos" type="text" 
                            @input="setPrecioSinImpuesto(index)" 
                            @blur="validaPrecioVenta(index)" 
                            class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat, validaMaximo]"
                            v-model="detalle.precio_con_impuestos"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <v-text-field :name="'nombre['+index+']'" label="De" class="center-input" type="text" autocomplete="off" 
                                :rules="[rules.required, validaCantidadFloat, validaRango(index), validaMaximo]" v-model="detalle.de"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <v-text-field :name="'nombre['+index+']'" label="a" class="center-input" type="text" autocomplete="off" 
                                :rules="[rules.required, validaCantidadFloat, validaRango(index), validaMaximo]" v-model="detalle.a"></v-text-field>
                        </v-col>                        
                        </v-row>
                    </div>
                    </v-container>
                </v-form>
                </fieldset>          

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="modal = false">
            <v-icon>cancel</v-icon> Cancelar
            </v-btn>
            <v-btn color="success" @click.native="updateRegistro()">
            <v-icon>done</v-icon> Actualizar
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- modal cantidad -->
    <v-dialog v-model="modalCantidad" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Precio</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCantidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                {{model_editable.nombre}}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                {{precio_editable}}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="6" md="6" lg="6" style="text-align:center !important;">
                                <v-text-field label="Nuevo Precio" v-model="cantidad_editable" ref="ingresacantidad" v-on:keydown.enter.prevent='1' @keyup.enter="valida_guardar()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalCantidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;&nbsp;
                            <v-btn small color="success" @click.native="valida_guardar()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                            
                            <div style="margin-top:12px;">
                                <v-btn small dark color="blue" @click.native="valida_guardar(1)">
                                    <v-icon>done_all</v-icon> Guardar y Crear Solicitud de Etiqueta
                                </v-btn>
                            </div>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_compras" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Historial Compras (Últimos 3 meses)</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_compras = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> PRECIO PROMEDIO DE COMPRA: <v-btn small text :loading="loadingPrecioPromedio" title="El Precio Promedio se calcula en base al precio de compra de las existencias. Si muestra 0 es que no hay existencias positivas del producto." v-tippy>${{ parseFloat(precio_promedio_compra).toFixed(2) }}</v-btn> </strong>
            <v-client-table ref="vuetable_compras" :data="items_compras" :columns="compras.columns" :options="compras.options">
                <template slot="fecha" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.fecha}}</div>
                </template>
                <template slot="existencia" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.existencia}}</div>
                </template>
                <template slot="cantidad" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.cantidad}}</div>
                </template>
                <template slot="precio" slot-scope="props">
                    <div style="font-size:10px !important;text-align:right;">{{ formatNumberDec(parseFloat(props.row.precio).toFixed(2),2)}}</div>
                </template>
                <template slot="proveedor" slot-scope="props">
                    <div class="columnaProveedor" style="font-size:10px !important;">{{props.row.proveedor}}</div>
                </template>
                
          </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_compras = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modal_existencias" max-width="35%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Existencias Por Sucursal</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_existencias = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> EXISTENCIA TOTAL: {{ parseFloat(existencia_articulo).toFixed(2) }}</strong>
            <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                  :columns="columnsExistencias" :options="optionsExistencias">
                  <template slot="sucursal" slot-scope="props">
                      <div style="font-size:10px !important;" >{{props.row.sucursal}}</div>
                  </template>
                  <template slot="existencia" slot-scope="props">
                      <div style="font-size:10px !important;text-align:right;" >{{formatNumberDecCero(props.row.existencia,2)}}</div>
                  </template>
              </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_existencias = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- modal fecha caducidad-->
    <v-dialog v-model="modalfechacaducidad" max-width="25%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Fecha Caducidad</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalfechacaducidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                <v-menu offset-x ref="menu_fecha" v-model="menu_fecha" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field v-on="on" v-model="articulo_editable.fecha_caducidad" label="Fecha" color="secondary" prepend-icon="event"
                                      ref="ingresafecha" readonly clearable
                                     @blur="articulo_editable.fecha_caducidad = parseDate(articulo_editable.fecha_caducidad)">
                                    </v-text-field>
                                  </template>

                                  <v-date-picker ref="ingresafecha" v-model="articulo_editable.fecha_caducidad" color="secondary" scrollable locale="es-mx" @input="menu_fecha = false">
                                    <v-spacer />

                                    <v-btn color="secondary" x-small @click="menu_fecha = false">
                                      Cancelar
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalfechacaducidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="saveFechaCaducidadArticulo()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                                                        
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';

import $ from 'jquery';
//import XLSX from 'xlsx';
export default {
    components: {
        "PaginacionComponent": PaginacionComponent
    },
    watch: {
        model_filters: {
            handler(val) {
                // this.$nextTick(() => {
                //   this.registros.items = [];
                //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                // });
            },
            deep: true
        },

    },
    mounted: function () {
        this.model_filters = this.$refs.vuetable.$refs.table.query;
        if (!this.verificaPermiso('t.compras.precios')) {
            this.$router.replace("AccessDenied").catch(() => {});
        }
    },
    created: function () {
        self.articulos_encontrados = [];
        this.usuario = this.$local_storage.get("sb_usuario");

        var today = window.moment().format("YYYY-MM-DD");
        this.fecha_de_venta = window.moment().subtract(3, 'months').format("YYYY-MM-DD");
        this.fecha_a_venta = today;
        this.getTiposPrecio();
        this.get_sucursales();
    },
    computed: {
        datafiltered() {
            if (this.$refs.vuetablearticulos != null) {
                return this.$refs.vuetablearticulos.allFilteredData;
            } else {
                return this.articulos_encontrados;
            }

        },
    },

    data() {
        return {
            tipos_precios: [],
            loadingPrecioPromedio:false,
            model_etiqueta: null,
            modal_existencias:false,
            modalfechacaducidad:false,
            menu_fecha:false,
            columnsExistencias: ["sucursal","existencia"],
            optionsExistencias: {
                filterable: false,
            },
            sucursales:[],
            articulo_existencias: [],
            existencia_articulo:"",
            precio_promedio_compra:0,
            modal_compras:false,
            items_compras: [],
            compras: {
                columns: [
                "fecha",                
                "cantidad",
                "precio",
                "proveedor",                
                ],
                options: {
                    filterable: false,
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        fecha: "Fecha",
                        cantidad: "Cantidad",
                        precio: "Precio Proveedor",
                        "proveedor":"Proveedor",                        
                    },
                }
            },
            articulos_equivalentes_orden:[],
            lakey: "1111",
            suc_matriz: "",
            suc_abastos: "",
            proveedores_combo: [],
            loadingCategorias: false,
            loadingProveedores: false,
            model_editable: "",
            model_index: 0,
            model_row: "",
            precio_editable: "",
            modal_opcion: 1,
            modalPlazoPago: false,
            loadingCompras: false,
            loadingArticulos: false,
            loadingVentas: false,
            columnsExistencias: ["sucursal", "existencia"],
            optionsExistencias: {
                filterable: false,
            },
            articulo_existencias: [],

            id_articulo_calculo: "",
            existencia_articulo: "",
            plazo_calculo: "",
            periodo: "1",
            fecha_de_venta: "",
            fecha_a_venta: "",
            fecha_de: "",
            fecha_a: "",
            ultimos_vendidos: 0,
            total_compra: 0,
            promedio_compra: 0,
            precio_promedio_compra: 0,
            total_venta: 0,
            promedio_venta: 0,
            precio_promedio_venta: 0,
            minimo: 0,
            reorden: 0,
            maximo: 0,
            cantidad_pedir: 0,
            consumo_minimo: 0,
            consumo_medio: 0,
            consumo_maximo: 0,
            existencia: 0,

            menu3: false,
            menu4: false,

            precios_articulo: [],
            columnsPrecios: ["tipo_precio", "precio", "de", "a"],
            optionsPrecios: {
                filterable: false,
            },
            fila_anterior: '',
            proveedores_all: [],
            categorias_all: [],
            modalBusqueda_copy: false,
            g_articulo: "",
            g_cantidad: 0,
            file_adjunto: null,
            model_adjuntos: [], //nombre, _id, _rev
            model_adjuntos_rev: "",

            estatus_editable: "",
            modalSugeridos: false,

            tipo_busqueda: "",
            tipos_busqueda: [{
                    "id": "proveedor",
                    "nombre": "Proveedor"
                },
                {
                    "id": "categoria",
                    "nombre": "Categoría"
                },
                {
                    "id": "articulo",
                    "nombre": "Artículo"
                },
            ],
            isLoading: false,
            sb_sucursal: "",
            nombre_sucursal: "",
            usuario: "",
            model_vista: {
                articulos: "",
                proveedor: ""
            },
            model_filters: "",
            props_paginacion: {
                infiniteHandler: this.infiniteHandler,
                total_registros: 0
            },
            fab: [],
            modal:false,
            modalCorreo: false,
            modalAdjuntarDocumentos: false,
            modalEstatus: false,
            modalVistaPrevia: false,
            modalObservacion: false,
            modalBusqueda: false,
            modalEditable: false,
            modalEditar: false,
            modalArticulos: false,
            modalCantidad: false,
            modalCantidadAbastos: false,
            cantidad_editable: "",
            articulo_editable: {},
            barcode_orden: "",
            row_selected: "",
            articulos_vista: [],
            menu1: false,
            menu2: false,
            name: "datagrid",

            columns: [
                "no_orden", "nombre_sucursal_destino", "fecha_recibio_mercancia", "usuario", "proveedor.nombre_proveedor", "articulos",
            ],
            options: {
                filterable: ["no_orden", "nombre_sucursal_destino", "fecha_recibio_mercancia", "usuario", "proveedor.nombre_proveedor", "articulos"],
                headings: {
                    "no_orden": "# Orden",
                    "nombre_sucursal_destino": "Sucursal",
                    "proveedor.nombre_proveedor": "Proveedor",
                    "plazo_pago": "Plazo para Pago",
                    "fecha_recibio_mercancia": "Fecha Recibió Mercancia"
                },

            },
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Compras",
                    href: "",
                    disabled: true,
                },
                {
                    text: "Revisar Precios",
                    href: ""
                }
            ],

            update: false,
            modelEditable: {},
            correo: {
                correos: "",
                responder_a: "",
                asunto: "",
                mensaje: ""
            },
            model_compras: {
                _id: "",
                _rev: "",
                type: "compras",
                no_orden: "",
                fecha: "",
                id_sucursal_destino: "",
                nombre_sucursal_destino: "",
                tipo_sucursal: "matriz", //matriz|abastos
                plazo_pago: 0, //dias de plazo para pago a proveedor
                proveedor: {
                    id_proveedor: "",
                    nombre_proveedor: "",
                    plazo: "",
                    contacto: "",
                    email: "",
                    telefono_1: ""
                },
                estatus: "", //Orden Abierta, En Proceso de Cotización, Cotización Recibida,
                //Pedido en Proceso, Orden Cancelada, En Proceso de Entrada, Pedido Recibido, Orden Finalizada
                articulos: [],
                usuario: "",
                observaciones_orden: "", //Se necesitan al imprimir la orden
                usuario_entrada: "",
                observaciones_entrada: "",
                fecha_finalizada: "",
            },
            model: {
                _id: "",
                _rev: "",
                type: "articulos",
                codigo_barras: [],
                nombre: "",
                created_at: "",
                estatus: "Activo",
                descripcion: "",
                categoria: "",
                caducidad: "",
                tiene_impuestos: "",
                tiene_grupo: "",
                tiene_informacion: "",
                marca: "",
                precios_venta: [],
                impuestos: [],
                grupo_articulos: [],
                informacion_nutrimental: [],
                producto_servicio: "",
                unidad_medida: "",
                precio_compra: "",
                precio_compra_con_iva: "",
                //nuevos
                equivalencias: [],
                proveedores: []        
            },
            actual: "",
            cantidad: "",
            actualEditable: "",
            cantidadEditable: "",
            id_articulo: "",
            id_proveedor: "",
            nombre_categoria: "",

            articulo: {
                id_articulo: "",
                nombre_articulo: "",
                codigo_articulo: "",

                precio_sin_iva: "", //Se carga con el excel que contesta el proveedor

                resaltado: 0, // 0, 1  Si está resaltado cambiarlo a color amarillo, para indicar que esos van a Abastos, los demas a central

            },

            columnsArticulosVista: ["tipo_articulo","codigo_articulo", "nombre_articulo","descripcion", "fecha_caducidad","existencia",
                "precio_proveedor", "publico", "diferencia_precio_publico", "utilidad_publico", 
                "mayoreo", "diferencia_precio_mayoreo", "utilidad_mayoreo", 
                "mayoreo2", "diferencia_precio_mayoreo2", "utilidad_mayoreo2","actions"
            ],
            optionsArticulosVista: {
                filterable: ["tipo_articulo","codigo_articulo", "nombre_articulo","descripcion", "fecha_caducidad", "existencia", "precio_proveedor"],
                headings: {
                    "tipo_articulo":"Origen",
                    "existencia":"Existencia",
                    "precio_proveedor": "Precio de Compra a Proveedor",
                    "publico": "Público en General",
                    "mayoreo": "Mayoreo",
                    "mayoreo2": "Mayoreo 2",
                    "diferencia_precio_publico":"$ Utilidad",
                    "diferencia_precio_mayoreo":"$ Utilidad",
                    "diferencia_precio_mayoreo2":"$ Utilidad",
                    "utilidad_publico":"Utilidad %",
                    "utilidad_mayoreo":"Utilidad %",
                    "utilidad_mayoreo2":"Utilidad %",
                    "actions":"Opciones"
                },
                cellClasses: {
                    precio_proveedor: [{
                        class: 'bg-resaltado',
                        condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && row.precio_proveedor != row.precio_compra_con_iva
                    }]
                }
            },

            rules: {
                required: v => !!v || "Este campo es requerido",
                entero: function (numero) {
                    const pattern = /^[\d]*$/;
                    return pattern.test(numero) || "Debe ser un número entero";
                },
            },
            search: "",
            searchProveedor: "",
            searchCategoria: "",
            registros: {
                selected: [],
                items: []
            },
            sucursales: [],
            all_sucursales: [],
            articulos: [],
            proveedores: [],
            categorias: [],
            articulos_encontrados: [],
        };
    },
    methods: {
        refresca() {
            this.lakey = "x" + Math.floor(Math.random() * 99999);
            console.log(this.lakey);
        },
        goHome() {
            this.$router.replace("/").catch(() => {});
        },
        cambiar: async function (row, tipo, index) {
            this.model_row = row;
            this.model_index = index - 1;
            this.cantidad_editable = "";
            if(tipo==1)
                this.cantidad_editable = row.publico;
            else if(tipo==2)
                this.cantidad_editable = row.mayoreo;
            else if(tipo==3)
                this.cantidad_editable = row.mayoreo2;

            await window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row.id_articulo)
                .then(response => {

                    this.model_editable = response.data;
                    this.modalCantidad = true;

                    if (tipo == 1) {
                        this.precio_editable = "Público General";
                    }
                    else if (tipo == 2) {
                        this.precio_editable = "Mayoreo";
                    }
                    else if (tipo == 3) {
                        this.precio_editable = "Mayoreo 2";
                    }

                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresacantidad.focus());
                    }, 100);

                })
                .catch(error => {
                    console.log(error);
                    window.dialogLoader.hide();
                });

        },
        valida_guardar: function(crear_etiqueta=0){

            if(parseFloat(this.model_row.precio_proveedor) > parseFloat(this.cantidad_editable)){
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "El precio de venta es menor al precio de compra ¿Aún asi desea continuar?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(resultado => {
                    if (resultado.value) {
                        this.guardar(crear_etiqueta);
                    } else {
                        this.modalCantidad = false;
                    }
                });
            } else {
                this.guardar(crear_etiqueta);
            }            
        },
        validarRangoNoRepetido: function (rangoExterior, rangoInterior) {
            // Verifica que el rango exterior no esté dentro del rango interior
            if (rangoExterior[0] >= rangoInterior[0] && rangoExterior[1] <= rangoInterior[1]) {
                return false;
            }

            // Verifica que el rango interior no esté dentro del rango exterior
            if (rangoInterior[0] >= rangoExterior[0] && rangoInterior[1] <= rangoExterior[1]) {
                return false;
            }

            // Verifica que los rangos no se solapen
            if (rangoExterior[0] >= rangoInterior[0] && rangoExterior[0] <= rangoInterior[1]) {
                return false;
            }

            if (rangoExterior[1] >= rangoInterior[0] && rangoExterior[1] <= rangoInterior[1]) {
                return false;
            }

            return true;
        },
        validaRango: function(index){
            var record1 = this.model.precios_venta[index];
            for(var krango in this.model.precios_venta){
                if(krango != index){
                    var record2 = this.model.precios_venta[krango];
                    var rango1 = [parseFloat(record1.de), parseFloat(record1.a)];
                    var rango2 = [parseFloat(record2.de), parseFloat(record2.a)];
                    if (this.validarRangoNoRepetido(rango1, rango2)) {
                        //console.log('El rango exterior'+index+' no se repite dentro del rango interior'+krango);
                    } else {
                        //console.log('El rango exterior'+index+' se repite dentro del rango interior'+krango);
                        return "El rango no debe repetirse o estar dentro de otro.";
                    }
                }
            }
            return true;
        },
    
        getTiposPrecio: function() {
        let data = {
            "selector": {
            "type": "configuracion",
            },

            "fields": ["_id", "tipos_precio"]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
            if (response.data.docs.length > 0)
                this.tipos_precios = response.data.docs[0].tipos_precio != undefined ? response.data.docs[0].tipos_precio : [];
            else
                this.tipos_precios = [];
            })
            .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener los tipos de precios.",
                footer: ""
            });
            });
        },
        getRegistro: function(id_registro) {

            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
            .then(response => {
                try {
                    this.model = response.data;
                    this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
                    if (this.model.informacion_nutrimental == null) {
                        this.model.informacion_nutrimental = [];
                        this.model.tiene_informacion = 0;
                    }            
                    this.update = true;
                    this.modal = true;
                } catch (err) {
                    console.log("ERROR: ", err);
                }

            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro",
                    footer: ""
                });
            });
        }, 
        formatNumberDec: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        formatNumberDecCero: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else{
              if(parseFloat(numero) == parseInt(numero))
                return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
              else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
                
        },
        get_sucursales: function () {            
            let data = {
                "selector": {
                    "nombre":{"$exists":true}
                },
                "fields": [
                    "nombre", "_id","abastos","matriz"
                ],
                "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.sucursales = [];
                    if (response.data.docs.length > 0){
                        this.sucursales = response.data.docs;                        
                    }                                      
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });
                });
        },
        getNombreSucursal: function(id_sucursal) {
            var filter = this.sucursales.find(e => e._id == id_sucursal);
            if (filter)
                return filter.nombre;
            else
                return "";
        },
        updateRegistro: function() {

            if (this.$refs.form.validate() && this.$refs.formprecios.validate() &&
                ((this.$refs.formimpuestos != null && this.$refs.formimpuestos.validate()) || this.$refs.formimpuestos == null)
            ) {

                window.dialogLoader.show('Espere un momento por favor..');        

                let data = this.model;
                data["_id"] = this.model._id;
                data["_rev"] = this.model._rev;

                if (data["precios_venta"].length == 0) {
                    window.dialogLoader.hide();
                    this.$swal({
                    type: "info",
                    title: "¡Datos Incompletos!",
                    text: "Debe agregar al menos un precio de venta. Favor de verificar.",
                    footer: ""
                    });
                    return true;
                }

                var flag_tipo = false;
                var flag_precio = false;
                var orden = [];
                if(data["precios_venta"].length > 1) {
                    data["precios_venta"].forEach((it) => {
                    it.de = parseFloat(it.de);
                    it.a = parseFloat(it.a);
                    let existe = this.tipos_precios.filter(x => x.nombre == it.tipo_precio);
                    if(existe.length > 0) {
                        existe = existe[0].orden;
                        orden.push({"orden": existe, "de": it.de, "a": it.a, "precio": it.precio_con_impuestos})
                    } else {
                        console.log("ya no existe un tipo de precio");
                    }
                    });

                    orden = orden.sort((a,b) => (a.orden < b.orden) ? -1 : (a.orden > b.orden) ? 1 : 0);
                    let tmp=null;
                    orden.forEach((or) => {
                    if(tmp == null) {
                        tmp = or.a;
                    } else {
                        if(tmp >= or.de) {
                        flag_tipo = true;
                        } else {
                        tmp = or.a;
                        }
                    }
                    });

                    let tmp2=null;
                    orden.forEach((or) => {
                    if(tmp2 == null) {
                        tmp2 = or.precio;
                    } else {
                        if(tmp2 < or.precio) {
                        console.log(tmp2, or.precio);
                        flag_precio = true;
                        } else {
                        tmp2 = or.precio;
                        }
                    }
                    });


                } else {
                    data["precios_venta"].forEach((it) => { //Convertimos a float 
                        it.de = parseFloat(it.de);
                        it.a = parseFloat(it.a);              
                    });
                }
                if (flag_tipo) {
                    window.dialogLoader.hide();
                    this.$swal({
                        icon: "info",
                        title: "¡Datos Incompletos!",
                        text: "Error en los tipos de precios. De o A no pueden ser iguales o menores a los tipos de precio con orden inferior.",
                        footer: ""
                    });
                    return true;
                }
                if (flag_precio) {
                    window.dialogLoader.hide();
                    this.$swal({
                        icon: "info",
                        title: "¡Datos Incompletos!",
                        text: "Error en los tipos de precios. El Precio de los tipos de precio no puede ser menor a un tipo de precio con orden mayor.",
                        footer: ""
                    });
                    return true;
                }
                
                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                    .then(response => {
                        window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                            color: 'success'
                        });
                        this.verArticulos(this.model_vista);
                    })
                    .catch(error => {
                        window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                            color: 'error'
                        });
                    }).then(() => {
                        this.modal = false;
                        window.dialogLoader.hide();
                    });
            
            }
        },
        setPrecio: function(index) {
            var impuestos = 0;
            this.model.precios_venta[index].porcentaje = parseFloat(this.model.precios_venta[index].porcentaje);
            var porciento = this.model.precios_venta[index].porcentaje;
            var precio_compra = this.model.precio_compra;
            //if(porciento != "" && precio_compra!=null && precio_compra!="" && parseFloat(porciento) >= 0 ){
            if (this.validaCantidadFloat(porciento) === true && this.validaCantidadFloat(precio_compra) === true) {
                this.model.precios_venta[index].precio = parseFloat(precio_compra) * (1 + (parseFloat(porciento) / 100));
                this.model.precios_venta[index].precio = parseFloat(this.model.precios_venta[index].precio.toFixed(2));
                for (var k in this.model.impuestos) {
                    impuestos += parseFloat(this.model.impuestos[k]["tasa"]);
                }
                this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio) * (1 + (parseFloat(impuestos) / 100));
                this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio_con_impuestos.toFixed(2));        
                //}
            } else {
                this.model.precios_venta[index].precio = "";
            }
        },
        addNewPrecio: function() {
            this.model.precios_venta.push(Vue.util.extend({}, []));
        },
        removePrecio: function(index) {
            Vue.delete(this.model.precios_venta, index);
        },
        validaTipoPrecio: function(val) {
            if (val != null && val != "") {
                var precios = this.model.precios_venta.filter(e => e.tipo_precio == val);
                if (precios.length >= 2)
                return "No debe repetir el tipo de precio";
            }
            return true;
        },
        ver_existencias: async function(id_articulo) {
            let n = 0;
            var self = this;
            await window.funciones_lotes.consultaExistencia({
                "id_articulo": id_articulo
            }).then(result => {
                if (result.length > 0) {

                    result.forEach(el => {
                        n += el.existencia
                        el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                    });
                    this.articulo_existencias = result;
                    this.existencia_articulo = n;
                } else {
                    this.articulo_existencias = [];
                }
                this.modal_existencias = true;
                //console.log("se asigno la existencia");

            }).catch(err => {
                console.log("error", err);
                this.existencia_articulo = 0;
                this.articulo_existencias = [];
            });
        },
        guardar: function (crear_etiqueta=0) {
            let self = this;
            let encontrado = false;

            //validar que el precio de Mayore 2, no sea mayor a Mayoreo, y Mayoreo no sea mayor a Público
            if(this.precio_editable == "Mayoreo 2" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(this.model_row.mayoreo.toString()!='N/A' && parseFloat(this.model_row.mayoreo) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(this.model_row.mayoreo)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo 2 es mayor a Mayoreo. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
                if(this.model_row.publico.toString()!='N/A' && parseFloat(this.model_row.publico) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(this.model_row.publico)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo 2 es mayor a Público General. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }else if(this.precio_editable == "Mayoreo" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(this.model_row.publico.toString()!='N/A' && parseFloat(this.model_row.publico) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(this.model_row.publico)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo es mayor a Público General. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }else if(this.precio_editable == "Público General" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(this.model_row.mayoreo2.toString()!='N/A' && parseFloat(this.model_row.mayoreo2) > 0){
                    if(parseFloat(this.cantidad_editable) < parseFloat(this.model_row.mayoreo2)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio Públigo General es menor a Mayoreo 2. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
                if(this.model_row.mayoreo.toString()!='N/A' && parseFloat(this.model_row.mayoreo) > 0){
                    if(parseFloat(this.cantidad_editable) < parseFloat(this.model_row.mayoreo)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio Públigo General es menor a Mayoreo. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }
            
            //console.log("ID: ", this.model_row.id_articulo);
            //console.log("Tipo: ", this.precio_editable);
            //console.log("Precio: ", this.cantidad_editable);
            var tipos = {"Mayoreo 2":"mayoreo2","Mayoreo":"mayoreo","Público General":"publico"}

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE
            + "/_design/funciones_articulos/_update/actualizar_precio_venta/" + this.model_row.id_articulo;

                var data = {
                    tipo_precio: this.precio_editable,
                    precio_con_impuestos: parseFloat(this.cantidad_editable),
                };

                window.axios
                .post(url, data)
                .then(response => {
                    if (response.data == true) {
                        window.dialogLoader.showSnackbar('El precio se actualizó correctamente..', {
                            color: 'success'
                        });
                        this.modalCantidad = false;
                        if(crear_etiqueta == 1){
                            this.solicitudEtiqueta();
                        }                        
                        self.model_vista.articulos[self.model_index][tipos[this.precio_editable]] = this.cantidad_editable;
                        this.refresca();
                    } else {
                        console.log("Precio No Actualizado");
                    }
                })
                .catch(error => {
                    console.log("Error: ",error);
                    window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                        color: 'error'
                    });
                }).then(() => {
                    this.modal = false;
                    window.dialogLoader.hide();
                });            

        },
        validaCantidadFloatNegativo: function(value) {
            const pattern = /^[\-]{0,1}[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && !pattern.test(value)) {
                return "Cantidad no válida"
            } else
                return true;

            },
        setPrecioConImpuesto: function(index) {
            //Se calcula el precio con impuesto y el porcentaje
            var porciento = 0;
            this.model.precios_venta[index].precio = parseFloat(this.model.precios_venta[index].precio);
            var precio_venta = this.model.precios_venta[index].precio;
            var precio_compra = this.model.precio_compra;
            if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
                if (precio_venta != null && precio_venta != "") {
                if (this.validaCantidadFloat(precio_venta) === true) {
                    for (var k in this.model.impuestos) {
                    porciento += parseFloat(this.model.impuestos[k]["tasa"]);
                    }
                    this.model.precios_venta[index].precio_con_impuestos = parseFloat(precio_venta) * (1 + (parseFloat(porciento) / 100));
                    this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio_con_impuestos.toFixed(2));
                    this.model.precios_venta[index].porcentaje = ((precio_venta / precio_compra) - 1) * 100;
                    this.model.precios_venta[index].porcentaje = parseFloat(this.model.precios_venta[index].porcentaje.toFixed(2));            
                }
                } else {
                this.model.precios_venta[index].precio_con_impuestos = "";
                }

            } else {
                this.$swal({
                type: "error",
                title: "Ingrese Precio de Compra",
                text: "Debe ingresar el precio de compra.",
                footer: ""
                }).then(() => {
                setTimeout(() => {
                    this.$nextTick(() => this.$refs.precio_compra_ref.focus());
                }, 100);
                });
            }

            },
            setPrecioSinImpuesto: function(index) {
            //Se calcula el precio sin impuesto y el porcentaje
            var porciento = 0;
            this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio_con_impuestos);
            var precio_venta = this.model.precios_venta[index].precio_con_impuestos;
            var precio_compra = this.model.precio_compra;
            if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
                if (precio_venta != null && precio_venta != "") {
                if (this.validaCantidadFloat(precio_venta) === true) {
                    for (var k in this.model.impuestos) {
                    porciento += parseFloat(this.model.impuestos[k]["tasa"]);
                    }
                    this.model.precios_venta[index].precio = parseFloat(precio_venta) / (1 + (parseFloat(porciento) / 100));
                    this.model.precios_venta[index].precio = parseFloat(this.model.precios_venta[index].precio.toFixed(2));
                    this.model.precios_venta[index].porcentaje = ((this.model.precios_venta[index].precio / precio_compra) - 1) * 100;
                    this.model.precios_venta[index].porcentaje = parseFloat(this.model.precios_venta[index].porcentaje.toFixed(2));            
                }
                } else {
                this.model.precios_venta[index].precio = "";
                }

            } else {
                this.$swal({
                type: "error",
                title: "Ingrese Precio de Compra",
                text: "Debe ingresar el precio de compra.",
                footer: ""
                }).then(() => {
                this.$nextTick(() => this.$refs.precio_compra_ref.focus());
                });
            }
        },
        validaPrecioVenta: function(index){
            var precio_venta = this.model.precios_venta[index].precio_con_impuestos;
            var precio_compra = this.model.precio_compra_con_iva;
            
            if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
                if (precio_venta != null && precio_venta != "" && this.validaCantidadFloat(precio_venta) === true) {
                if (parseFloat(precio_compra) >= parseFloat(precio_venta)) {
                    this.$swal({
                    type: "warning",
                    title: "!Cuidado¡",
                    text: "El precio de compra es menor o igual al precio de venta. Favor de verificar.",
                    footer: ""
                    });
                }
                }
            }      
        },
        solicitudEtiqueta: function () {
            var self = this;

            if(this.model_etiqueta != null){//Si ya se habia creado una solicitud, agregamos ahi los articulos que vayan cambiando de precio

                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_etiqueta._id)
                .then(response => {
                    this.model_etiqueta = response.data;

                    this.model_etiqueta.estatus = "Pendiente";

                    var cantidad_mayoreo = 0;
                    if(self.precio_editable == "Mayoreo"){
                        self.model_editable.precios_venta.forEach(function(e){
                            if(e.tipo_precio == "Mayoreo")
                                cantidad_mayoreo = e.de;
                        });
                    }
            
                    this.model_etiqueta.articulos.push({
                        id_articulo: self.model_editable._id,
                        nombre_articulo: self.model_editable.nombre,
                        descripcion: self.model_editable.descripcion,
                        codigo_articulo: self.model_editable.codigo_barras,
                        cantidad: 1,
                        precio: parseFloat(self.cantidad_editable),
                        tipo_precio: self.precio_editable,
                        mayoreo: cantidad_mayoreo
                    });
                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_etiqueta._id + '?conflicts=true', this.model_etiqueta)
                        .then(response => {
                            this.model_etiqueta._rev = response.data.rev;  
                            window.dialogLoader.showSnackbar('Solicitud Enviada', {
                                color: 'success'
                            });            
                        })
                        .catch(error => {
                            window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente nuevamente', {
                                color: 'error'
                            });
                        });
                })
                .catch(error => {
                    window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente Nuevamente', {
                        color: 'error'
                    });
                });
                
            } else {
                var cantidad_mayoreo = 0;
                if(self.precio_editable == "Mayoreo"){
                    self.model_editable.precios_venta.forEach(function(e){
                        if(e.tipo_precio == "Mayoreo")
                            cantidad_mayoreo = e.de;
                    });
                }
                var etq = {
                    type: "etiquetas",
                    fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                    estatus: "Pendiente",
                    articulos: [{
                        id_articulo: self.model_editable._id,
                        nombre_articulo: self.model_editable.nombre,
                        descripcion: self.model_editable.descripcion,
                        codigo_articulo: self.model_editable.codigo_barras,
                        cantidad: 1,
                        precio: parseFloat(self.cantidad_editable),
                        tipo_precio: self.precio_editable,
                        mayoreo: cantidad_mayoreo
                    }],
                    usuario: self.usuario,
                    observaciones: "Revisión Precios en Compra",
                };
                this.model_etiqueta = etq;

                window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
                    .then(response => {
                        //console.log("Response", response);
                        this.model_etiqueta["_rev"] = response.data.rev;
                        this.model_etiqueta["_id"] = response.data.id;
                        window.dialogLoader.showSnackbar('Solicitud Enviada', {
                            color: 'success'
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente nuevamente.', {
                            color: 'error'
                        });
                    });   
            }            

        },
        infiniteHandler: function ($state) {
            var rows_per_page = 10;
            var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
            var skip = page * rows_per_page;
            var where = {
                "type": "compras",
                "estatus":{"$in": ["Cotización Recibida", "Pedido en Proceso", "En Proceso de Entrada", "Pedido Recibido","Orden Finalizada"]} ,
                "fecha":{"$exists":true}
            };

            this.options.filterable.forEach(x => {
                if (this.model_filters[x]) {
                    where[x] = {
                        "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    }
                }
            });

            window.axios({
                    method: 'POST',
                    url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                    withCredentials: true,
                    data: {
                        limit: rows_per_page,
                        skip: skip,
                        selector: where,
                        sort:[{"fecha":"desc"}],
                        "use_index": "_design/40ba1d8ad8b0faf45cc2b1f6f7c45ea18e9d5f1f"
                    },
                }).then(response => {
                    if (response.data.docs.length) {
                        /*response.data.docs.sort(function (a, b) {
                            //Ordenar por fecha más reciente
                            return new Date(b.fecha) - new Date(a.fecha);
                        });*/
                        var unique_records = new Set(this.registros.items.map(item => item._id));
                        unique_records = Array.from(unique_records.values());
                        var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
                        this.registros.items = this.registros.items.concat(nuevos);                        
                        this.props_paginacion.total_registros = this.registros.items.length;
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        
        
        parse_date(date) {
            if (!date) return null;
            return window.moment(String(date)).format("YYYY-MM-DD");
        },
        parse_date_time(date) {
            if (!date) return null;
            return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
        },
        parse_date_time_mx(date) {
            if (!date) return null;
            return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
        },

        verArticulos: async function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            
            var articulos_con_equivalentes = [];

            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(async responseOrden => {
                row = responseOrden.data;
                await Promise.all(row.articulos.map(async (x) => {                    
                    
                    if (x.precio_proveedor == undefined || x.precio_proveedor == "")
                        x.precio_proveedor = 0.00;

                    x.precio_proveedor = parseFloat(x.precio_proveedor).toFixed(2);
                    await window.axios
                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + x.id_articulo)
                        .then(async response => {                            

                            if (response.data.precios_venta != undefined && response.data.precios_venta.length > 0) {
                                x.publico = "N/A";
                                x.mayoreo = "N/A";
                                x.mayoreo2 = "N/A";
                                response.data.precios_venta.forEach(function (y) {
                                    if (y.tipo_precio == "Público General") {
                                        x.publico = parseFloat(y.precio_con_impuestos).toFixed(2);
                                    }
                                    if (y.tipo_precio == "Mayoreo") {
                                        x.mayoreo = parseFloat(y.precio_con_impuestos).toFixed(2);
                                    }
                                    if (y.tipo_precio == "Mayoreo 2") {
                                        x.mayoreo2 = parseFloat(y.precio_con_impuestos).toFixed(2);
                                    }
                                })
                            }

                            let datos_consulta = {
                                "id_articulo": x.id_articulo,                        
                            };

                            await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                                x["existencia"] = 0;
                                if (result.length > 0) {
                                    result.forEach(el => {
                                        x["existencia"] += el.existencia;
                                    });
                                }                                                                     
                            }).catch(err => {
                                console.log("Ocurrió un error al consultar existencia de artículo: ", x._id);
                            });

                            x["tipo_articulo"] = "Compra";
                            articulos_con_equivalentes.push(x);

                            //Traemos recursivamente los articulos equivalentes
                            await this.get_equivalencias(x.id_articulo, articulos_con_equivalentes);

                        })
                        .catch(error => {
                            console.log(error);
                            window.dialogLoader.hide();
                        });                        


                }));
                articulos_con_equivalentes.sort(this.ordenar_nombre);
                row.articulos = articulos_con_equivalentes;
                this.model_vista = row;
                this.modalArticulos = true;
                window.dialogLoader.hide();
            }).catch(error => {
                console.log("Error al obtener orden de compra",error); 
                this.modalArticulos = false;
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los artículos. Intente nuevamente",
                    footer: ""
                });  
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');                            
            })

        },
        get_equivalencias: async function(id_articulo, articulos_con_equivalentes){
            var busca = encodeURI(escape(id_articulo));
            await window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
            .then(async responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                    for(var ke in responseEquivalencias.data.rows){
                        var equivalencia = responseEquivalencias.data.rows[ke].value;
                        await window.axios
                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + equivalencia.id_articulo_equivalente)
                        .then(async responseArticulo => {
                            try{
                                var equivalente = responseArticulo.data;
                                equivalente["tipo_articulo"] = "Equivalente";
                                equivalente["id_articulo"] = equivalente._id;
                                equivalente["codigo_articulo"] = equivalente.codigo_barras;
                                equivalente["nombre_articulo"] = equivalente.nombre;
                                if (equivalente.precio_compra_con_iva == undefined || equivalente.precio_compra_con_iva == "")
                                    equivalente["precio_compra_con_iva"] = 0.00;

                                equivalente["precio_proveedor"] = parseFloat(equivalente.precio_compra_con_iva).toFixed(2);
                                if (equivalente.precios_venta != undefined && equivalente.precios_venta.length > 0) {
                                    equivalente["publico"] = "N/A";
                                    equivalente["mayoreo"] = "N/A";
                                    equivalente["mayoreo2"] = "N/A";
                                    equivalente.precios_venta.forEach(function (y) {
                                        if (y.tipo_precio == "Público General") {
                                            equivalente["publico"] = parseFloat(y.precio_con_impuestos).toFixed(2);
                                        }
                                        if (y.tipo_precio == "Mayoreo") {
                                            equivalente["mayoreo"] = parseFloat(y.precio_con_impuestos).toFixed(2);
                                        }
                                        if (y.tipo_precio == "Mayoreo 2") {
                                            equivalente["mayoreo2"] = parseFloat(y.precio_con_impuestos).toFixed(2);
                                        }
                                    })

                                }
                                let datos_consulta = {
                                    "id_articulo": equivalente["id_articulo"],                        
                                };

                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                                    equivalente["existencia"] = 0;
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            equivalente["existencia"] += el.existencia;
                                        });
                                    }                                                                     
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo: ", equivalente["id_articulo"]);
                                });

                                articulos_con_equivalentes.push(equivalente);
                                await this.get_equivalencias(equivalente["id_articulo"], articulos_con_equivalentes);
                            } catch(err){
                                console.log("ERROR al leer articulo equivalente: ",err);
                            }
                        }).catch(error => {
                            console.log("Error al obtener articulo equivalente",error);                            
                        })
                    }
                }
            }).catch(error => {
                console.log("Error al buscar equivalencias",error);                            
            });
        },
        ordenar_nombre: function( a, b ){//Ordenar por dos propiedades   
            if (a.nombre_articulo.toLowerCase() === b.nombre_articulo.toLowerCase()){
                return a.tipo_articulo.toLowerCase() < b.tipo_articulo.toLowerCase() ? -1 : 1
            } else {
                return a.nombre_articulo.toLowerCase() < b.nombre_articulo.toLowerCase() ? -1 : 1
            }         
            /*if ( (a.nombre_articulo.toLowerCase()+a.tipo_articulo.toLowerCase()) < (b.nombre_articulo.toLowerCase()+b.tipo_articulo.toLowerCase())){
                return -1;
            }
            if ( (a.nombre_articulo.toLowerCase()+a.tipo_articulo.toLowerCase()) > (b.nombre_articulo.toLowerCase()+b.tipo_articulo.toLowerCase())){
                return 1;
            }*/
            return 0;
        },
        validaCantidadFloatRequerido: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0)
                    return "Debe ser mayor que cero";
            } else if (value != null && value == "")
                return "Campo requerido";
            return true;
        },
        validaCantidadEntera: function (value) {
            const pattern = /^[\d]*$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseInt(value) <= 0)
                    return "Debe ser mayor que cero";
            } else if (value != null && value == "")
                return "Campo requerido";
            return true;
        },
        validaMaximo: function(value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && pattern.test(value)) {
                if(parseFloat(value) > 100000)
                return "Cantidad demasiado grande";
            } 
            return true;

        },
        validaCantidadFloat: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0)
                    return "Debe ser mayor que cero";
            }
            return true;
        },

        openModal: function (type) {
            if (type === 1) {
                this.update = false;

            } else {
                this.update = true;
            }
            this.modal_opcion = 1; //1-Articulos por proveedor, 2-Sugeridos
            this.modalBusqueda = true;
            /*setTimeout(() => {
                this.$nextTick(() => this.$refs.selectbusqueda.focus());
            }, 100);*/
        },

        valida_fecha: function (fecha) {
            if (fecha == "Invalid date")
                return "Fecha no válida."
            else
                return true;
        },

        editArticulo: function (articulo) {
            this.articulo_editable = articulo;
            this.modalEditable = true;
        },

        validarArticulo: function () {
            if (this.$refs.formcantidad.validate()) {
                this.agregarArticulo('matriz');
            }
        },

        format: function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
        },

        consultar_compras: async function () {
            try {
                this.loadingCompras = true;
                this.items_compras = [];

                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
                const [year_de, month_de, day_de] = this.fecha_de_venta.split("-");
                const [year_a, month_a, day_a] = this.fecha_a_venta.split("-");
                let urlConditions = `?startkey=["${this.id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

                axios.get(url + urlConditions)
                    .then(response => {
                        if (response.data.rows.length > 0) {
                            let respuesta = response.data.rows;
                            let procesado = [];

                            //Aqui ya seria agrupar por dia, semana o mes
                            //console.log("busqueda por dias")
                            respuesta.forEach(x => {
                                let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                                if (x.value.precio_sin_iva.toString().trim() != "") {
                                    procesado.push({
                                        "fecha": key,
                                        "cantidad": x.value.cantidad,
                                        "existencia": x.value.existencia,
                                        "precio": x.value.precio_sin_iva,
                                        "proveedor": x.value.proveedor.nombre_proveedor,
                                        "total": 0
                                    });
                                }
                            });
                            //console.log(procesado);

                            // Sacar los datos de la parte de abajo
                            this.total_compra = 0;
                            this.promedio_compra = 0;
                            this.precio_promedio_compra = 0;

                            procesado.forEach(x => {
                                this.total_compra += x.cantidad;
                                this.promedio_compra += x.cantidad;
                                this.precio_promedio_compra += x.precio;
                            });

                            let numero_dias_tiempo = new Date(this.fecha_a_venta) - new Date(this.fecha_de_venta);
                            let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);

                            this.promedio_compra = this.promedio_compra > 0 ? Math.ceil(this.promedio_compra / numero_dias) : 0;
                            this.precio_promedio_compra = this.precio_promedio_compra > 0 ? this.precio_promedio_compra / procesado.length : 0;

                            //Poner arreglo en reversa para mostrar la compra mas reciente al inicio
                            this.items_compras = procesado.reverse();
                        } else {
                            this.items_compras = [];
                        }
                    })
                    .catch(error => {
                        this.items_compras = [];
                        console.log(error)
                    }).then(() => {
                        this.loadingCompras = false;
                    });
            } catch (error) {
                console.log("Error al consultar compras: ", error);
            }
        },
        consultar_compras_ORIGINAL: function () {
            try {
                this.loadingCompras = true;
                this.items_compras = [];
                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/';
                let sort = "fecha";

                let data = {
                    "selector": {
                        "type": "compras",
                        "articulos": {
                            "$elemMatch": {
                                "id_articulo": this.id_articulo_calculo
                            }
                        },
                        "fecha": {
                            "$gte": this.fecha_de_venta,
                            "$lte": this.fecha_a_venta + "T23:59:59.999"
                        },
                        "estatus": "Orden Finalizada"
                    },
                    "sort": [{
                        "fecha": "desc"
                    }],
                };

                axios({
                        method: 'POST',
                        url: url,
                        withCredentials: true,
                        data: data,
                    }).then(response => {
                        if (response.data.docs.length > 0) {
                            let respuesta = response.data.docs;
                            let dias = {};

                            for (var i = 0; i < respuesta.length; i++) {

                                let fecha = respuesta[i].fecha.toString().substring(0, 10);
                                let articulos_filtrados = respuesta[i].articulos.filter(x => this.id_articulo_calculo == x.id_articulo);
                                if (fecha in dias) {
                                    for (var j = 0; j < articulos_filtrados.length; j++) {
                                        dias[fecha].push({
                                            "cantidad": articulos_filtrados[j].cantidad,
                                            "precio_anterior": articulos_filtrados[j].precio_compra,
                                            "precio": articulos_filtrados[j].precio_sin_iva,
                                            "proveedor": respuesta[i].proveedor.nombre_proveedor,
                                            "total": 0
                                        })
                                    }
                                } else {
                                    dias[fecha] = [];

                                    for (var j = 0; j < articulos_filtrados.length; j++) {
                                        dias[fecha].push({
                                            "cantidad": articulos_filtrados[j].cantidad,
                                            "precio_anterior": articulos_filtrados[j].precio_compra,
                                            "precio": articulos_filtrados[j].precio_sin_iva,
                                            "proveedor": respuesta[i].proveedor.nombre_proveedor,
                                            "total": 0
                                        })
                                    }
                                }
                            }
                            let procesado = [];
                            //Aqui ya seria agrupar por dia, semana o mes
                            for (const [key, value] of Object.entries(dias)) {
                                let cantidad = 0;
                                let precio = 0;
                                let precio_anterior = 0;
                                let proveedor = "";
                                for (var i = 0; i < value.length; i++) {
                                    cantidad += value[i].cantidad;
                                    precio += value[i].precio;
                                    precio_anterior += value[i].precio_anterior;
                                    proveedor = value[i].proveedor
                                }
                                let promedio = precio == 0 ? 0 : (precio / value.length)
                                let promedio_anterior = precio_anterior == 0 ? 0 : (precio_anterior / value.length)
                                procesado.push({
                                    "fecha": key,
                                    "cantidad": cantidad,
                                    "precio": promedio,
                                    "precio_anterior": promedio_anterior,
                                    "proveedor": proveedor,
                                    "total": 0
                                });
                            }

                            // Sacar los datos de la parte de abajo
                            this.total_compra = 0;
                            this.promedio_compra = 0;
                            this.precio_promedio_compra = 0;

                            procesado.forEach(x => {
                                this.total_compra += x.cantidad;
                                this.promedio_compra += x.cantidad;
                                this.precio_promedio_compra += x.precio;
                            });

                            let numero_dias_tiempo = new Date(this.fecha_a_venta) - new Date(this.fecha_de_venta);
                            let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);

                            this.promedio_compra = this.promedio_compra > 0 ? Math.ceil(this.promedio_compra / numero_dias) : 0;
                            this.precio_promedio_compra = this.precio_promedio_compra > 0 ? this.precio_promedio_compra / procesado.length : 0;

                            this.items_compras = procesado;
                        } else {
                            this.items_compras = [];
                        }
                    })
                    .catch(error => {
                        this.items_compras = [];
                        console.log(error)
                    }).then(() => {
                        this.loadingCompras = false;
                    });
            } catch (error) {
                console.log("Error al consultar compras: ", error);
            }

        },
        getCompras: async function(id_articulo_calculo) {
            try {
            var self = this;
                window.dialogLoader.show('Espere un momento por favor..'); 
                this.items_compras = [];
                self.calcula_precio_promedio(id_articulo_calculo);

                var today = window.moment().format("YYYY-MM-DD");
                var fecha_de_venta = window.moment().subtract(3, 'months').format("YYYY-MM-DD");
                var fecha_a_venta = today;

                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
                const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                let urlConditions = `?startkey=["${id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

                axios.get(url + urlConditions)
                .then(response => {
                    if (response.data.rows.length > 0) {
                        let respuesta = response.data.rows;
                        let procesado = [];                        

                        //Aqui ya seria agrupar por dia, semana o mes
                        //console.log("busqueda por dias")
                        respuesta.forEach(x => {
                            let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                            if(x.value.precio_proveedor.toString().trim() != ""){
                                procesado.push({
                                    "fecha": key,
                                    "cantidad": x.value.cantidad,
                                    "existencia": x.value.existencia,
                                    "precio": x.value.precio_proveedor,
                                    "proveedor": x.value.proveedor.nombre_proveedor,
                                    "total": 0
                                });                                
                            }
                        });
                        //console.log(procesado);

                        //Poner arreglo en reversa para mostrar la compra mas reciente al inicio
                        this.items_compras = procesado.reverse();
                    } else {
                        this.items_compras = [];
                    }
                })
                .catch(error => {
                    this.items_compras = [];
                    console.log(error)
                }).then(()=>{
                    this.modal_compras = true;
                    window.dialogLoader.hide();
                });
            } catch (error) {
                console.log("Error al consultar compras: ", error);
            }
        },
        calcula_precio_promedio: async function(id_articulo_calculo){
            var self = this;
            this.precio_promedio_compra = 0;
            self.loadingPrecioPromedio = true;
            
            var precio_sumatoria = 0;
            var cantidad_lotes = 0;

            for(var k in this.sucursales){
                var id_sucursal = this.sucursales[k]._id;
                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/lotes_articulo';
                let urlConditions = `?startkey=["${id_sucursal}","${id_articulo_calculo}"]&endkey=["${id_sucursal}","${id_articulo_calculo}"]`;
                await axios.get(url + urlConditions)
                .then(response => {
                    if (response.data.rows.length > 0) {
                        let lotes = response.data.rows;                  
                        //console.log("LOTES: ", lotes);
                        for(var kl in lotes){
                        if( parseFloat(lotes[kl].value.precio_compra) > 0){
                            precio_sumatoria += parseFloat(lotes[kl].value.precio_compra);
                            cantidad_lotes += 1;
                        }
                        }
                    
                } 
                }).catch(error => {
                
                });
            }

            if(cantidad_lotes > 0){
            self.precio_promedio_compra = precio_sumatoria > 0 ? precio_sumatoria / cantidad_lotes : 0;
            }
            self.loadingPrecioPromedio = false;
            
        },
        calcular_fecha: function (fecha) {
            this.fecha_de_venta = moment(String(fecha)).format("YYYY-MM-DD");;
            this.fecha_a_venta = moment().format("YYYY-MM-DD");

            this.calcular();
        },
        parseDate(date) {
            if (!date) return null;
            return moment(String(date)).format("YYYY-MM-DD");
        },
        showModalFechaCaducidad: function(row){
            this.articulo_editable = row;
            if(row.fecha_caducidad != null && row.fecha_caducidad != undefined && row.fecha_caducidad != ""){
                this.articulo_editable.fecha_caducidad = this.parseDate(row.fecha_caducidad);
            }
            this.modalfechacaducidad=true;
        },
        
        saveFechaCaducidadArticulo: function(){
            window.dialogLoader.show('Espere un momento por favor..');
            var self = this;
            
            var art_find = this.model_vista.articulos.find(a => a.id_articulo == self.articulo_editable.id_articulo)
            if(art_find){
                if(self.articulo_editable.fecha_caducidad != null && self.articulo_editable.fecha_caducidad != undefined && self.articulo_editable.fecha_caducidad != ""){
                    art_find.fecha_caducidad = window.moment(self.articulo_editable.fecha_caducidad).format("YYYY-MM-DDTHH:mm:ss");
                } else {
                    art_find.fecha_caducidad = "";
                }
                
            } 

            //traerse el modelo de la compra y actualizar solo la fecha del articulo
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
            .then(async response => {
                var model = response.data;

                var art_model = model.articulos.find(a => a.id_articulo == self.articulo_editable.id_articulo)
                if(art_model){
                    if(self.articulo_editable.fecha_caducidad != null && self.articulo_editable.fecha_caducidad != undefined && self.articulo_editable.fecha_caducidad != ""){
                        art_model.fecha_caducidad = window.moment(self.articulo_editable.fecha_caducidad).format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        art_model.fecha_caducidad = "";
                    }                    
                } 
                
                //Actualizamos la compra
                window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + model._id + '?conflicts=true', model)
                .then(async response => {
                    model = response.data.rev;
                    this.modalfechacaducidad = false;

                    //Si tiene lotes destino el articulo, se modifica su fecha caducidad
                    if(art_find && art_find.lotes_destino && art_find.lotes_destino.length>0){
                        for(var kl in art_find.lotes_destino){
                            var lote_id = art_find.lotes_destino[kl]['lote'];
                            
                            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_lotes/_update/actualizar_fecha_caducidad/" + lote_id;
                            let data = { fecha_caducidad: art_find.fecha_caducidad };

                            await window.axios
                                .post(url, data)
                                .then(responseLote => {
                                    if (responseLote.data == true) {
                                        //console.log("Lote: "+lote_id+" Caducidad actualizada correctamente");                                                
                                    } else {
                                        //console.log("Error Lote: "+lote_id+" no se actualizó caducidad");
                                    }

                                }).catch(error => {
                                    console.log("Exception al actualizar fecha de caducidad en lote: "+error);                            
                                })
                        }
                    }
                    window.dialogLoader.hide();
                    
                })
                .catch(error => {
                    console.log("Error2", error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al guardar la fecha. Intente nuevamente.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                }).then(() => {
                    //self.model_vista.articulos[self.model_index]['fecha_caducidad'] = window.moment(self.articulo_editable.fecha_caducidad).format("YYYY-MM-DDTHH:mm:ss");
                    this.refresca();                
                });

            }).catch(error => {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            });                                
        },

    }
};
</script>

<style>
.bg-menor {
    /*background-color: #861306 !important;*/
    background-color: #d75151 !important;
}

.footer-hide>>>.VuePagination {
    display: none;
}

.celda_titulo {
    font-size: 8px;
    text-align: center;
    color: red;
    border: 1px solid black !important;
}

.celda_datos {
    font-size: 8px;
    border: 1px solid black !important;
}

.celda_datos_resaltado {
    font-size: 8px;
    border: 1px solid black !important;
    background-color: #F5C142 !important;
}

.celda_datos_seleccionado {
    font-size: 8px;
    border: 1px solid black !important;
    /*background-color: #EF9A9A !important;*/
    background-color: #ABF6AF !important;
}

.v-dialog .table-bordered thead th,
.table-bordered thead td {
    font-size: x-small !important;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
}

.v-dialog .form-control {
    font-size: x-small !important;
}

.v-dialog fieldset legend {
    font-size: small !important;
}

.v-dialog #tableBuscar .v-input__control {
    font-size: 10px !important;
}

.v-dialog #tableBuscar .v-label {
    font-size: 10px !important;
}

.v-dialog .columnaProveedor {
    white-space: normal !important;
    min-width: 200px !important;
    max-width: 200px !important;
    /*text-align: justify !important;    */
}

.v-dialog .columnaArticulo {
    white-space: normal !important;
    min-width: 200px !important;
    max-width: 200px !important;
    /*text-align: justify !important;    */
}

.v-dialog .columnaUnidad .columnaPlazo {
    white-space: normal !important;
    min-width: 100px !important;
    max-width: 100px !important;
    /*text-align: justify !important;    */
}

.v-dialog #tablePrecios .col-md-12 {
    margin-bottom: -25px;
}

.v-dialog #tableBuscar .col-md-12 {
    margin-bottom: -25px;
}

.v-dialog #tableBuscarSugeridos .col-md-12 {
    margin-bottom: -25px;
}

.v-dialog #tableExistencias .col-md-12 {
    margin-bottom: -25px;
}

.v-dialog #tableCompras .col-md-12 {
    margin-bottom: -25px;
}

.v-dialog #tableVentas .col-md-12 {
    margin-bottom: -25px;
}

#filtroPeriodo .v-label,
.error--text {
    font-size: 9px !important;
}
</style>
